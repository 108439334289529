var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "개선 목록",
              tableId: "impr01",
              columns: _vm.gridImpr.columns,
              data: _vm.riskReduce.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.setheight,
            },
            on: { linkClick: _vm.linkClick },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }